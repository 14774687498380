import React, { useState } from "react";
import "./ourPartners.css";
import deutscheglasfaser from "../../assets/deutscheglasfaser.svg";
import homenet from "../../assets/homenet.svg";
import locate from "../../assets/locate.svg";
import giganetz from "../../assets/giganetz.svg";
import tng from "../../assets/tng.svg";
import maxprom from "../../assets/maxprom.svg";
import Avc from "../../assets/Avc.svg";
import Title from "../../components/Title";

const Partners = ({ isEnglish }) => {
  const partnersArray = [
    {
      name: deutscheglasfaser,
      extraClass: "partner-logo",
    },
    {
      name: homenet,
      extraClass: "partner-logo",
    },
    {
      name: locate,
      extraClass: "partner-logo",
    },
    {
      name: giganetz,
      extraClass: "partner-logo",
    },
    {
      name: tng,
      extraClass: "partner-logo",
    },
    // {
    //   name: Avc,
    //   extraClass: "partner-logo",
    // },
    // {
    //   name: maxprom,
    //   extraClass: 'ibm',
    // },
  ];

  return (
    <div className="partners__body">
      <div className="partners__container">
        <div className="partners__titleLogo">
          <Title header={isEnglish ? "Our Customers" : "Unsere Kunden"} />
          <div className="partners__logos">
            <img src={maxprom} alt="" className="maxpromLogo" />
            <img src={Avc} alt="" className="avcLogo" />
          </div>
        </div>

        <div className="partners__container displayNone">
          <Title
            header={isEnglish ? "Supported Providers" : "Unterstützte Anbieter"}
          />
          <div className="partners__images">
            {partnersArray.map((partner, index) => {
              return (
                <img
                  src={partner.name}
                  className={partner.extraClass}
                  key={index}
                  alt={index}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Partners;
