/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";


const PdfComponentEn = () => {
  const pdfUrl = "https://oneri-pdf.s3.eu-central-1.amazonaws.com/ONERI-EN.pdf"
  const pdfUrlMobile = "https://drive.google.com/file/d/1XeM0c7s34nh-HmDqeP8xW2CI4c6Ue_I7/preview?usp=share_link"

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  return (
    <div className="pdf-component">
      {isMobile ? (
        <iframe src={pdfUrlMobile} frameborder="0"></iframe>
      ) : (
        <iframe src={pdfUrl} frameborder="0"></iframe>
      )}
    </div>
  );
};

export default PdfComponentEn;
