import React, { useEffect, useState } from "react";
import "./index.css";
import Footer from "../footer/footer";
import logo from "../../assets/oneri_logo.png";
import { privacyData } from "./privacyPolicyDatas";
import { useNavigate } from "react-router";
import { scroller, animateScroll } from "react-scroll";
import burger from "../../assets/burgermenu.svg";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../navBar/navBar";

function PrivacyPolicy({ isEnglish, setIsEnglish }) {
  const [data, setData] = useState(privacyData);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const currentDate = new Date().getFullYear();

  useEffect(() => {
    scrollToTop();
  }, []);
  function scrollToTop() {
    animateScroll.scrollToTop({
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <>
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>

      <div className="privacyPolicy__text__container">
        <div className="privacyPolicy__text__section1">
          <span className="privacyPolicy__text__header">
            1. Datenschutz auf einen Blick
          </span>
          <span className="privacyPolicy__text__title">
            Allgemeine Hinweise
          </span>
          <span className="privacyPolicy__text__text">
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </span>
          <span className="privacyPolicy__text__title">
            Datenerfassung auf dieser Website
          </span>
          <span className="privacyPolicy__text__subTitle">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </span>
          <span className="privacyPolicy__text__text">
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </span>
          <span className="privacyPolicy__text__subTitle">
            Wie erfassen wir Ihre Daten?
          </span>
          <span className="privacyPolicy__text__text">
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch oder
            nach Ihrer Einwilligung beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
          </span>
          <span className="privacyPolicy__text__subTitle">
            Wofür nutzen wir Ihre Daten?
          </span>
          <span className="privacyPolicy__text__text">
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </span>
          <span className="privacyPolicy__text__subTitle">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </span>
          <span className="privacyPolicy__text__text">
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu
            sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
            jederzeit an uns wenden.
          </span>
          <span className="privacyPolicy__text__subTitle">
            Analyse-Tools und Tools von Drittanbietern
          </span>
          <span className="privacyPolicy__text__text">
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen. Detaillierte Informationen zu diesen
            Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
          </span>
          <span className="privacyPolicy__text__header">
            2. Hosting und Content Delivery Networks (CDN)
          </span>
          <span className="privacyPolicy__text__title">Externes Hosting</span>
          <span className="privacyPolicy__text__text">
            Diese Website wird bei einem externen Dienstleister gehostet
            (Hoster). Die personenbezogenen Daten, die auf dieser Website
            erfasst werden, werden auf den Servern des Hosters gespeichert.
            Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
            und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Websitezugriffe und sonstige Daten, die über eine Website generiert
            werden, handeln. Der Einsatz des Hosters erfolgt zum Zwecke der
            Vertragserfüllung gegenüber unseren potenziellen und bestehenden
            Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren,
            schnellen und effizienten Bereitstellung unseres Online-Angebots
            durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
            Erfüllung seiner Leistungspflichten erforderlich ist und unsere
            Weisungen in Bezug auf diese Daten befolgen.
          </span>
          <span className="privacyPolicy__text__text">
            Wir setzen folgenden Hoster ein:
          </span>
          <span className="privacyPolicy__text__text">1&1 IONOS SE</span>
          <span className="privacyPolicy__text__text">
            Der Datenschutzbeauftragte
          </span>
          <span className="privacyPolicy__text__text">
            Elgendorfer Straße 57
          </span>
          <span className="privacyPolicy__text__text">56410 Montabaur</span>
          <span className="privacyPolicy__text__header">
            3. Allgemeine Hinweise und Pflichtinformationen
          </span>
          <span className="privacyPolicy__text__title">Datenschutz</span>
          <span className="privacyPolicy__text__text">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z. B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </span>
          <span className="privacyPolicy__text__title">Speicherdauer</span>
          <span className="privacyPolicy__text__text">
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z.B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </span>
          <span className="privacyPolicy__text__title">
            Hinweis zur Datenweitergabe in die USA
          </span>
          <span className="privacyPolicy__text__text">
            Auf unserer Website sind unter anderem Tools von Unternehmen mit
            Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können
            Ihre personenbezogenen Daten an die US-Server der jeweiligen
            Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
            USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
            US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
            Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
            hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
            ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre
            auf US-Servern befindlichen Daten zu Überwachungszwecken
            verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
            Verarbeitungstätigkeiten keinen Einfluss.
          </span>
          <span className="privacyPolicy__text__title">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </span>
          <span className="privacyPolicy__text__text">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </span>
          <span className="privacyPolicy__text__title">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </span>
          <span className="privacyPolicy__text__text">
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E
            oder F DSGVO erfolgt, haben sie jederzeit das Recht, aus Gründen,
            die sich aus ihrer besonderen Situation ergeben, gegen die
            Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen;
            Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen sie dieser Datenschutzerklärung. Wenn sie Widerspruch
            einlegen, werden wir ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            gründe für die Verarbeitung nachweisen, die ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach art. 21 Abs. 1 DSGVO). Werden ihre
            personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
            so haben sie das recht, jederzeit Widerspruch gegen die Verarbeitung
            sie betreffender personenbezogener Daten zum Zwecke derartiger
            Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
            solcher Direktwerbung in Verbindung steht. Wenn sie widersprechen,
            werden ihre personenbezogenen Daten anschliessend nicht mehr zum
            zwecke der direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
            DSGVO).
          </span>
          <span className="privacyPolicy__text__title">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </span>
          <span className="privacyPolicy__text__text">
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </span>
          <span className="privacyPolicy__text__title">
            Recht auf Datenübertragbarkeit
          </span>
          <span className="privacyPolicy__text__text">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </span>
          <span className="privacyPolicy__text__title">
            SSL- bzw. TLS-Verschlüsselung
          </span>
          <span className="privacyPolicy__text__text">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </span>
          <span className="privacyPolicy__text__title">
            Auskunft, Löschung und Berichtigung
          </span>
          <span className="privacyPolicy__text__text">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </span>
          <span className="privacyPolicy__text__title">
            Recht auf Einschränkung der Verarbeitung
          </span>
          <span className="privacyPolicy__text__text">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen: uns wenden.
          </span>
          <ul className="privacyPolicy__text__list">
            <li className="privacyPolicy__text__list__li">
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li className="privacyPolicy__text__list__li">
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li className="privacyPolicy__text__list__li">
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li className="privacyPolicy__text__list__li">
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <span className="privacyPolicy__text__text">
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </span>
          <span className="privacyPolicy__text__header">
            4. Datenerfassung auf dieser Website
          </span>
          <span className="privacyPolicy__text__title">Cookies</span>
          <span className="privacyPolicy__text__text">
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt. Teilweise können auch
            Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden,
            wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese
            ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen
            des Drittunternehmens (z.B. Cookies zur Abwicklung von
            Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen.
            Zahlreiche Cookies sind technisch notwendig, da bestimmte
            Websitefunktionen ohne diese nicht funktionieren würden (z.B. die
            Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
            dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
            anzuzeigen. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
            bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies,
            z. B. für die Warenkorbfunktion) oder zur Optimierung der Website
            (z.B. Cookies zur Messung des Webpublikums) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
            sofern keine andere Rechtsgrundlage angegeben wird. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
            Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
            betreffenden Cookies ausschließlich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
            jederzeit widerrufbar. Sie können Ihren Browser so einstellen, dass
            Sie über das Setzen von Cookies informiert werden und Cookies nur im
            Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
            oder generell ausschließen sowie das automatische Löschen der
            Cookies beim Schließen des Browsers aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein. Soweit Cookies von Drittunternehmen oder zu
            Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen
            dieser Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </span>
          <span className="privacyPolicy__text__title">Server-Log-Dateien</span>
          <span className="privacyPolicy__text__text">
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </span>
          <ul className="privacyPolicy__text__list">
            <li className="privacyPolicy__text__list__li">
              Browsertyp und Browserversion
            </li>
            <li className="privacyPolicy__text__list__li">
              verwendetes Betriebssystem
            </li>
            <li className="privacyPolicy__text__list__li">Referrer URL</li>
            <li className="privacyPolicy__text__list__li">
              Hostname des zugreifenden Rechners
            </li>
            <li className="privacyPolicy__text__list__li">
              Uhrzeit der Serveranfrage
            </li>
            <li className="privacyPolicy__text__list__li">IP-Adresse</li>
          </ul>
          <span className="privacyPolicy__text__text">
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website – hierzu müssen die Server-Log-Files
            erfasst werden.
          </span>
          <span className="privacyPolicy__text__title">Kontaktformular</span>
          <span className="privacyPolicy__text__text">
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten
            erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
            Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
            übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
            Interesse an der effektiven Bearbeitung der an uns gerichteten
            Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde. Die von
            Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
            gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen –
            bleiben unberührt.
          </span>
          <span className="privacyPolicy__text__title">
            Anfrage per E-Mail, Telefon oder Telefax
          </span>
          <span className="privacyPolicy__text__text">
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
            beruht die Verarbeitung auf unserem berechtigten Interesse an der
            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs.
            1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) sofern diese abgefragt wurde. Die von Ihnen an uns per
            Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen – insbesondere gesetzliche
            Aufbewahrungsfristen – bleiben unberührt.
          </span>
          <span className="privacyPolicy__text__header">5. Soziale Medien</span>
          <span className="privacyPolicy__text__title">
            Facebook Plugins (Like & Share-Button)
          </span>
          <span className="privacyPolicy__text__text">
            Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
            integriert. Anbieter dieses Dienstes ist die Facebook Ireland
            Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
            werden nach Aussage von Facebook jedoch auch in die USA und in
            andere Drittländer übertragen. Die Facebook Plugins erkennen Sie an
            dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf dieser
            Website. Eine Übersicht über die Facebook Plugins finden Sie hier:{" "}
            <a
              href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
              target="__blank"
            >
              https://developers.facebook.com/docs/plugins/?locale=de_DE
            </a>
            . Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
            Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook
            „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account
            eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
            Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser
            Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
            wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Facebook erhalten.
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
            von Facebook unter:{" "}
            <a
              href="https://de-de.facebook.com/privacy/explanation"
              target="__blank"
            >
              https://de-de.facebook.com/privacy/explanation
            </a>
            . Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
            Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
            Ihrem Facebook-Benutzerkonto aus. Die Verwendung der Facebook
            Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an einer möglichst
            umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar. Soweit mit Hilfe des hier
            beschriebenen Tools personenbezogene Daten auf unserer Website
            erfasst und an Facebook weitergeleitet werden, sind wir und die
            Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irland gemeinsam für diese Datenverarbeitung
            verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit
            beschränkt sich dabei ausschließlich auf die Erfassung der Daten und
            deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende
            Verarbeitung durch Facebook ist nicht Teil der gemeinsamen
            Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden
            in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den
            Wortlaut der Vereinbarung finden Sie unter:{" "}
            <a
              href="https://www.facebook.com/legal/controller_addendum"
              target="__blank"
            >
              https://www.facebook.com/legal/controller_addendum
            </a>
            . Laut dieser Vereinbarung sind wir für die Erteilung der
            Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
            datenschutzrechtlich sichere Implementierung des Tools auf unserer
            Website verantwortlich. Für die Datensicherheit der
            Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
            (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten
            Daten können Sie direkt bei Facebook geltend machen. Wenn Sie die
            Betroffenenrechte bei uns geltend machen, sind wir verpflichtet,
            diese an Facebook weiterzuleiten. Die Datenübertragung in die USA
            wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
            Details finden Sie hier:{" "}
            <a
              href="https://www.facebook.com/legal/controller_addendum"
              target="__blank"
            >
              https://www.facebook.com/legal/controller_addendum
            </a>
            ,
            <a
              href="https://de-de.facebook.com/help/566994660333381"
              target="__blank"
            >
              https://de-de.facebook.com/help/566994660333381
            </a>{" "}
            und{" "}
            <a href="https://www.facebook.com/policy.php" target="__blank">
              https://www.facebook.com/policy.php
            </a>
            .
          </span>
          <span className="privacyPolicy__text__title">Instagram Plugin</span>
          <span className="privacyPolicy__text__text">
            Auf dieser Website sind Funktionen des Dienstes Instagram
            eingebunden. Diese Funktionen werden angeboten durch die Facebook
            Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
            2, Irland integriert. Wenn Sie in Ihrem Instagram-Account eingeloggt
            sind, können Sie durch Anklicken des Instagram-Buttons die Inhalte
            dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann
            Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
            Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
            durch Instagram erhalten. Die Speicherung und Analyse der Daten
            erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an einer möglichst
            umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar. Soweit mit Hilfe des hier
            beschriebenen Tools personenbezogene Daten auf unserer Website
            erfasst und an Facebook bzw. Instagram weitergeleitet werden, sind
            wir und die Facebook Ireland Limited, 4 Grand Canal Square, Grand
            Canal Harbour, Dublin 2, Irland gemeinsam für diese
            Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
            Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
            Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram.
            Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook
            bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung. Die uns
            gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung
            über gemeinsame Verarbeitung festgehalten. Den Wortlaut der
            Vereinbarung finden Sie unter:{" "}
            <a
              href="https://www.facebook.com/legal/controller_addendum"
              target="__blank"
            >
              https://www.facebook.com/legal/controller_addendum
            </a>
            . Laut dieser Vereinbarung sind wir für die Erteilung der
            Datenschutzinformationen beim Einsatz des Facebook- bzw.
            Instagram-Tools und für die datenschutzrechtlich sichere
            Implementierung des Tools auf unserer Website verantwortlich. Für
            die Datensicherheit der Facebook bzw. Instagram-Produkte ist
            Facebook verantwortlich. Betroffenenrechte (z.B. Auskunftsersuchen)
            hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten
            können Sie direkt bei Facebook geltend machen. Wenn Sie die
            Betroffenenrechte bei uns geltend machen, sind wir verpflichtet,
            diese an Facebook weiterzuleiten. Die Datenübertragung in die USA
            wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
            Details finden Sie hier:{" "}
            <a
              href="https://www.facebook.com/legal/EU_data_transfer_addendum"
              target="__blank"
            >
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>
            ,
            <a
              href="https://help.instagram.com/519522125107875"
              target="__blank"
            >
              https://help.instagram.com/519522125107875
            </a>{" "}
            und{" "}
            <a
              href="https://de-de.facebook.com/help/566994660333381"
              target="__blank"
            >
              https://de-de.facebook.com/help/566994660333381
            </a>
            . Weitere Informationen hierzu finden Sie in der
            Datenschutzerklärung von Instagram:{" "}
            <a
              href="https://instagram.com/about/legal/privacy/"
              target="__blank"
            >
              https://instagram.com/about/legal/privacy/
            </a>
            .
          </span>
          <span className="privacyPolicy__text__header">
            6. Analyse-Tools und Werbung
          </span>
          <span className="privacyPolicy__text__title">
            WordPress Statistik
          </span>
          <span className="privacyPolicy__text__text">
            Diese Website nutzt „WordPress Statistik“, um Besucherzugriffe
            statistisch auszuwerten. Anbieter ist die Automattic Inc., 60 29th
            Street #343, San Francisco, CA 94110-4929, USA. WordPress Statistik
            verwendet Technologien, die die Wiedererkennung des Nutzers zum
            Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B. Cookies
            oder Device-Fingerprinting). WordPress Statistik erfasst zur Analyse
            u. a. Logdateien (Referrer, IP-Adresse, Browser u. a.), die Herkunft
            der Websitebesucher (Land, Stadt) und welche Aktionen sie auf der
            Seite getätigt haben (z.B. Klicks, Ansichten, Downloads). Die so
            erfassten Informationen über die Benutzung dieser Website werden auf
            Servern in den USA gespeichert. Ihre IP-Adresse wird nach der
            Verarbeitung und vor der Speicherung anonymisiert. Die Nutzung
            dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
            DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
            anonymisierten Analyse des Nutzerverhaltens, um sowohl sein
            Webangebot als auch seine Werbung zu optimieren. Sofern eine
            entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
            zur Speicherung von Cookies), erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </span>
          <span className="privacyPolicy__text__header">
            7. Plugins und Tools
          </span>
          <span className="privacyPolicy__text__title">Vimeo</span>
          <span className="privacyPolicy__text__text">
            Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
            Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
            Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten
            besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
            Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
            besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch
            dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account
            bei Vimeo besitzen. Die von Vimeo erfassten Informationen werden an
            den Vimeo-Server in den USA übermittelt. Wenn Sie in Ihrem
            Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo, Ihr
            Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
            können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account
            ausloggen. Zur Wiedererkennung der Websitebesucher verwendet Vimeo
            Cookies bzw. vergleichbare Wiedererkennungstechnologien (z.B.
            Device-Fingerprinting). Die Nutzung von Vimeo erfolgt im Interesse
            einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt
            ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO
            dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
            die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO; die Einwilligung ist jederzeit widerrufbar. Die
            Datenübertragung in die USA wird auf die Standardvertragsklauseln
            der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte
            Geschäftsinteressen“ gestützt. Details finden Sie hier:{" "}
            <a href="https://vimeo.com/privacy" target="__blank">
              https://vimeo.com/privacy
            </a>
            . Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Vimeo unter:{" "}
            <a href="https://vimeo.com/privacy" target="__blank">
              https://vimeo.com/privacy
            </a>
            .
          </span>
          <span className="privacyPolicy__text__title">Google Maps</span>
          <span className="privacyPolicy__text__text">
            Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
            Google Ireland Limited („Google“), Gordon House, Barrow Street,
            Dublin 4, Irland. Zur Nutzung der Funktionen von Google Maps ist es
            notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden
            in der Regel an einen Server von Google in den USA übertragen und
            dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
            diese Datenübertragung. Die Nutzung von Google Maps erfolgt im
            Interesse einer ansprechenden Darstellung unserer Online-Angebote
            und an einer leichten Auffindbarkeit der von uns auf der Website
            angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne
            von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar. Die Datenübertragung in die
            USA wird auf die Standardvertragsklauseln der EU-Kommission
            gestützt. Details finden Sie hier:{" "}
            <a
              href="https://privacy.google.com/businesses/gdprcontrollerterms/"
              target="__blank"
            >
              https://privacy.google.com/businesses/gdprcontrollerterms/
            </a>{" "}
            und
            <a
              href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
              target="__blank"
            >
              https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
            </a>
            . Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Google:
            <a
              href="https://policies.google.com/privacy?hl=de"
              target="__blank"
            >
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </span>
          <span className="privacyPolicy__text__title">Facebook-Pixels</span>
          <span className="privacyPolicy__text__text">
            Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die
            Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung
            von Anzeigen (sogenannte “Facebook-Ads”) zu bestimmen.
            Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns
            geschalteten Facebook-Ads nur solchen Nutzern bei Facebook und
            innerhalb der Dienste der mit Facebook kooperierenden Partner (so
            genanntes “Audience Network”{" "}
            <a
              href="https://www.facebook.com/audiencenetwork/"
              target="__blank"
            >
              https://www.facebook.com/audiencenetwork/
            </a>{" "}
            ) anzuzeigen, die auch ein Interesse an unserem Onlineangebot
            gezeigt haben oder die bestimmte Merkmale (z.B. Interesse an
            bestimmten Themen oder Produkten, die anhand der besuchten Webseiten
            ersichtlich werden) aufweisen, die wir an Facebook übermitteln
            (sogenannte “Custom Audiences“). Mit Hilfe des Facebook-Pixels
            möchten wir auch sicherstellen, dass unsere Facebook-Ads dem
            potentiellen Interesse der Nutzer entsprechen und nicht belästigend
            wirken. Mit Hilfe des Facebook-Pixels können wir ferner die
            Wirksamkeit der Facebook-Werbeanzeigen für statistische und
            Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer
            nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Webseite
            weitergeleitet wurden (sogenannte “Konversionsmessung“).
          </span>
          <ul className="privacyPolicy__text__list">
            <li className="privacyPolicy__text__list__li">
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten,
              die den Standort des Endgeräts eines Endnutzers angeben).
            </li>
            <li className="privacyPolicy__text__list__li">
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li className="privacyPolicy__text__list__li">
              Zwecke der Verarbeitung: Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
              Remarketing, Besuchsaktionsauswertung, Interessenbasiertes und
              verhaltensbezogenes Marketing, Profiling (Erstellen von
              Nutzerprofilen), Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen), Zielgruppenbildung (Bestimmung von für
              Marketingzwecke relevanten Zielgruppen oder sonstige Ausgabe von
              Inhalten), Cross-Device Tracking (geräteübergreifende Verarbeitung
              von Nutzerdaten für Marketingzwecke).
            </li>
            <li className="privacyPolicy__text__list__li">
              Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
              IP-Adresse).
            </li>
            <li className="privacyPolicy__text__list__li">
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <li className="privacyPolicy__text__list__li">
              Widerspruchsmöglichkeit (Opt-Out): Wir verweisen auf die
              Datenschutzhinweise der jeweiligen Anbieter und die zu den
              Anbietern angegebenen Widerspruchsmöglichkeiten (sog. “Opt-Out”).
              Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde,
              besteht zum einen die Möglichkeit, dass Sie Cookies in den
              Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
              Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
              empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten,
              die zusammenfassend auf jeweilige Gebiete gerichtet angeboten
              werden: a) Europa:{" "}
              <a href="https://www.youronlinechoices.eu" target="__blank">
                https://www.youronlinechoices.eu
              </a>
              . b) Kanada:
              <a href=" https://www.youradchoices.ca/choices" target="__blank">
                {" "}
                https://www.youradchoices.ca/choices.
              </a>{" "}
              c) USA:{" "}
              <a href="https://www.aboutads.info/choices" target="__blank">
                https://www.aboutads.info/choices
              </a>
              . d) Gebietsübergreifend:
              <a href="http://optout.aboutads.info." target="__blank">
                http://optout.aboutads.info
              </a>
            </li>
          </ul>
        </div>

        <div className="privacyPolicy__header">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            src={logo}
            alt=""
          />
          <span>© Copyright {currentDate} ONERI</span>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;

{
  /* <span className="privacyPolicy__text__header">
        1. Datenschutz auf einen Blick
        </span>
        <span className="privacyPolicy__text__title">
        Allgemeine Hinweise
        </span>
        <span className="privacyPolicy__text__text">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was
        mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen
        zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
        aufgeführten Datenschutzerklärung.
</span> */
}

{
  /* <span className="privacyPolicy__text__subTitle">JETON</span> */
}

<ul className="privacyPolicy__text__list">
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
</ul>;
